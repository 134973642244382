<template>
  <div id="home">
    <h1><img src="../assets/logo_white_shadow.png" alt="ソラシロ合同会社/sorashiro LLC." /></h1>
    <Navigation/>
  </div>
</template>

<script>
// @ is an alias to /src
import Navigation from '@/components/Navigation.vue'

export default {
  name: 'Home',
  components: {
    Navigation
  },
  metaInfo: {
    bodyAttrs: {
      id: 'pg-home'
    },
    meta: [
      { name: 'description', content: '私たちソラシロは広島でUnityを使ったゲーム制作・アプリ制作からインタラクティブコンテンツ制作までを行うクリエイティブ集団です。どんなご相談でもお気軽にご相談ください。' }
    ],
    link: [
      { rel: 'canonical', href: 'https://sorashiro.com/' }
    ]
  }
}
</script>

<style scoped lang="scss">

#home{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

::v-deep nav {
    ul{
        li{
          display: inline-block;
          &:first-child{
              display: none;
          }
        }

       @include mq-max(){
          li{
            display: block;
            font-size: .95em;
            margin: 1.5em 0;
            line-height: 1.3em;
            &:after{
              display: inline-block;
            }
          }
        }
    }
}

h1 img {
    width: 340px;
    @include mq-max(){
        width: 220px;
         @media (orientation: landscape){
            width: 60vh;
            margin-bottom: 0;
        }
    }
}

</style>
